import React from "react"
import styled, { css } from "styled-components"
import { graphql } from "gatsby"

import { Header } from "../../components/Header"
import { Layout } from "../../components/layout"
import { Footer } from "../../components/Footer"
import { Section } from "../../components/Section"
import { Container, getHPadding } from "../../components/Container"
import { Content } from "../../components/text/Content"
import { P } from "../../components/text/P"
import { FluidBackgroundImage } from "../../components/FluidBackgroundImage"
import Img from "gatsby-image"

const CenteredContent = styled(Content)`
  margin-left: auto;
  margin-right: auto;
`

const H1White = styled.h1`
  text-align: center;
  color: white;
`

const H2 = styled.h2`
  color: ${(props) => props.theme.ascent};
`

const CenteredP = styled(P)`
  text-align: center;
`

const ImageTextContainerStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => 64 / theme.baseSize}rem;
  :last-child {
    margin-bottom: 0;
  }
`

const ImageStyled = styled(Img)`
  ${({ maxWidth, minWidth, smallScreenHeight, theme }) => {
    const hPadding = getHPadding(maxWidth) / theme.baseSize
    maxWidth = maxWidth / theme.baseSize
    minWidth = minWidth / theme.baseSize
    smallScreenHeight = smallScreenHeight / theme.baseSize
    return css`
      flex: 1 1 ${minWidth}rem;
      max-width: ${maxWidth}rem;
      @media screen and (max-width: ${maxWidth + hPadding * 2}rem) {
        height: ${smallScreenHeight}rem;
      }
    `
  }};
  ${({ invert, maxWidth, theme }) => {
    const hPadding = getHPadding(maxWidth) / theme.baseSize
    maxWidth = maxWidth / theme.baseSize
    if (invert === true) {
      return css`
        order: 1; /* Image on right side */
        @media screen and (max-width: ${maxWidth + hPadding * 2}rem) {
          order: 0; /* Image on top instead of bottom */
        }
      `
    } else {
      return ""
    }
  }};
`

const BodyStyled = styled.div`
  ${({ maxWidth, minWidth, padding, theme }) => {
    padding = padding / theme.baseSize
    maxWidth = maxWidth / theme.baseSize
    minWidth = minWidth / theme.baseSize
    return css`
      flex: 1 1 ${minWidth + padding * 2}rem;
      max-width: ${maxWidth + padding * 2}rem;
      padding: ${padding}rem;
    `
  }};
`

const Page = (props) => {
  const page = props.data.allDataJson.edges[0].node.pages.services_development
  return (
    <Layout meta={page.meta} loadUikit={true}>
      <Header />
      <FluidBackgroundImage fluid={page.title_bg.childImageSharp.fluid}>
        <Section>
          <Container>
            <CenteredContent maxWidth={720}>
              <H1White>{page.title}</H1White>
              <CenteredP data={page.subtitle} />
            </CenteredContent>
          </Container>
        </Section>
      </FluidBackgroundImage>
      <Section>
        <Container>
          <ImageTextContainerStyled>
            <ImageStyled
              minWidth={440}
              maxWidth={787}
              smallScreenHeight={300}
              fluid={page.automation_si.image.childImageSharp.fluid}
            />
            <BodyStyled minWidth={300} maxWidth={767} padding={24}>
              <H2>{page.automation_si.title}</H2>
              <P data={page.automation_si.desc} />
            </BodyStyled>
          </ImageTextContainerStyled>
          <ImageTextContainerStyled>
            <ImageStyled
              minWidth={440}
              maxWidth={787}
              smallScreenHeight={300}
              invert={true}
              fluid={page.hardware_dev.image.childImageSharp.fluid}
            />
            <BodyStyled minWidth={300} maxWidth={767} padding={24}>
              <H2>{page.hardware_dev.title}</H2>
              <P data={page.hardware_dev.desc} />
            </BodyStyled>
          </ImageTextContainerStyled>
          <ImageTextContainerStyled>
            <ImageStyled
              minWidth={440}
              maxWidth={787}
              smallScreenHeight={300}
              fluid={page.software_dev.image.childImageSharp.fluid}
            />
            <BodyStyled minWidth={300} maxWidth={767} padding={24}>
              <H2>{page.software_dev.title}</H2>
              <P data={page.software_dev.desc} />
            </BodyStyled>
          </ImageTextContainerStyled>
        </Container>
      </Section>
      <Footer />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          pages {
            services_development {
              meta {
                url
                title
                description
                imageRootUrl
                image
              }
              title
              subtitle
              title_bg {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 80) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
              automation_si {
                title
                desc
                image {
                  childImageSharp {
                    fluid(maxWidth: 689, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              hardware_dev {
                title
                desc
                image {
                  childImageSharp {
                    fluid(maxWidth: 689, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              software_dev {
                title
                desc
                image {
                  childImageSharp {
                    fluid(maxWidth: 689, quality: 80) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
